// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { config } from "utils/AppConfig";

export enum EErrorTypes {
  "COMMENT_IS_MANDATORY" = "COMMENT_IS_MANDATORY",
  "NOT_FOUND_ISSUE_TO_EDIT" = "NOT_FOUND_ISSUE_TO_EDIT",
  "UNABLE_TO_CHANGE_STATUS_WHEN_MODIFIED" = "UNABLE_TO_CHANGE_STATUS_WHEN_MODIFIED",
  "UNABLE_TO_CHANGE_STATUS_TO_THE_SAME_ONE" = "UNABLE_TO_CHANGE_STATUS_TO_THE_SAME_ONE",
  "UNABLE_TO_CHANGE_ISSUE_STATUS_WHEN_ASSET_IS_REMOVED" = "UNABLE_TO_CHANGE_ISSUE_STATUS_WHEN_ASSET_IS_REMOVED",
  "UNABLE_TO_CHANGE_STATUS_WHEN_ISSUE_STATUS_IS_CLOSED" = "UNABLE_TO_CHANGE_STATUS_WHEN_ISSUE_STATUS_IS_CLOSED"
}

export type IUpdateIssuesErrorObject = {
  [key in EErrorTypes]: string[];
};

export type IUpdateIssuesErrorObjectResponse = {
  Errors: IUpdateIssuesErrorObject;
  SuccessCount: 0;
};

export type IUpdateIssuesSuccessResponse = string;

export type IUpdateIssuesErrorResponse =
  | IUpdateIssuesErrorObjectResponse
  | EErrorTypes.COMMENT_IS_MANDATORY;

export type IUpdateIssuesWarningResponse = {
  Errors: IUpdateIssuesErrorObject;
  SuccessCount: number;
};

export type IUpdateIssuesResponse =
  | IUpdateIssuesSuccessResponse
  | IUpdateIssuesErrorResponse
  | IUpdateIssuesWarningResponse;

const updateIssuesStatusAction = (
  status: IssueStatuses,
  rows: IRow[],
  reason: string
): Promise<IUpdateIssuesResponse> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.watchlist.bulkUpdateIssueStatusUrl
    );

    const issuesToUpdate = rows.reduce((acc, { data }) => {
      acc[data.AssetIssueId] = data.Status;
      return acc;
    }, {} as { [key: string]: IssueStatuses });

    const body = {
      IssuesToUpdate: issuesToUpdate,
      Status: status,
      Reason: reason
    };

    EndpointService.put(
      url,
      resolve,
      reject,
      null,
      body,
      "application/json; charset=utf-8"
    );
  });

export default updateIssuesStatusAction;
