// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import styled from "styled-components";
import { fontSizeSmall, spacingSmall } from "styles/StyleVariables";

interface IAssetWithLocationColumnProps {
  assetId: string;
  location?: string;
  className?: string;
}

const AssetWithLocationColumn = ({
  assetId,
  location,
  className
}: IAssetWithLocationColumnProps) => {
  const navigate = useAppNavigate();

  const searchParams = new SearchParams({ assetId });

  return (
    <div className={className}>
      <a
        className="anchor id link-label"
        onClick={() =>
          navigate({
            pathname: routes.detailPage.pathname,
            search: searchParams.toString()
          })
        }
      >
        {assetId}
      </a>
      {location && <div className="details">{location}</div>}
    </div>
  );
};

const gridPadding = "10px";

export default styled(AssetWithLocationColumn)`
  padding: ${gridPadding};

  .id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .details {
    margin-top: ${spacingSmall};
    font-size: ${fontSizeSmall};
  }
`;
