// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { editIssueStatus } from "features/detailpage/features/issues/actions/IssuesActions";
import IssueDetailsHeader, {
  IIssueDetailsHeaderActions
} from "features/detailpage/features/issues/components/IssueDetailsHeader";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch } from "store";

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueDetailsHeaderActions {
  return {
    editIssueStatus: (
      intl: IntlShape,
      issueId: string,
      status: IssueStatuses,
      comment: string
    ) => editIssueStatus(dispatch, intl, issueId, status, comment)
  };
}

export default connect(undefined, mapDispatchToProps)(IssueDetailsHeader);
