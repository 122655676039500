// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Select as AntdSelect } from "antd";
import { IRow, IRowData } from "common/datagrid/DataGrid";
import IssueStatusChangeConfirmationModal from "features/detailpage/features/issues/components/IssueStatusChangeConfirmationModal";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import useIssueStatusTranslation from "../hooks/useIssueStatusTranslation";

interface ICheckedBar {
  rows: IRow<IRowData>[];
  disabled?: boolean;
  handleChange: (status: IssueStatuses, reason?: string) => Promise<void>;
}

const CheckedBar = ({ rows, disabled, handleChange }: ICheckedBar) => {
  const [status, setStatus] = useState<IssueStatuses>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const issueStatusTranslation = useIssueStatusTranslation();
  const intl = useIntl();
  const options = [
    {
      value: IssueStatuses.InProgress,
      label: issueStatusTranslation[IssueStatuses.InProgress]
    },
    {
      value: IssueStatuses.Monitor,
      label: issueStatusTranslation[IssueStatuses.Monitor]
    },
    {
      value: IssueStatuses.Closed,
      label: issueStatusTranslation[IssueStatuses.Closed]
    }
  ];

  useEffect(() => {
    if (!status) {
      return;
    }

    setModalVisible(true);
  }, [status]);

  return (
    <Wrapper>
      {!disabled && (
        <CheckedTitle data-qa="selected-issues-counter-title">
          <FormattedMessage
            id="issues_page.issues.selected.title"
            defaultMessage="Selected: {numberOfRows}"
            values={{
              numberOfRows: (
                <span data-qa="selected-issues-counter-number">
                  {rows.length}
                </span>
              )
            }}
          />
        </CheckedTitle>
      )}
      <Select
        value={status}
        disabled={disabled || rows.length === 0}
        options={options}
        placeholder={intl.formatMessage({
          id: "issues_page.issues.selected.placeholder",
          defaultMessage: "Change status"
        })}
        onChange={setStatus}
      />
      <IssueStatusChangeConfirmationModal
        status={status}
        visible={modalVisible}
        numberOfIssues={rows.length}
        resetForm={() => setStatus(null)}
        hideModal={() => {
          setStatus(null);
          setModalVisible(false);
        }}
        onConfirmAction={handleChange}
      />
    </Wrapper>
  );
};

const CheckedTitle = styled.span`
  margin-right: 30px;
  font-size: 15px;
  font-weight: 600;
  color: ${colorPrimary};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`;

const Select = styled(AntdSelect)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${colorPrimary};
  }

  &:not(.ant-select-disabled) {
    .ant-select-selection-item,
    .ant-select-selection-placeholder,
    .ant-select-arrow {
      color: white;
    }
  }
`;

export default CheckedBar;
