// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IColumn, IRow } from "common/datagrid/DataGrid";
import HeaderCell from "common/datagrid/HeaderCell";

export interface IHeaderRowProps {
  columns: IColumn[];
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
  showCheckboxColumn?: boolean;
  onCheckAll?: (e: CheckboxChangeEvent) => void;
  checkedAll?: boolean;
  checkedRows: IRow[];
}

const HeaderRow = (props: IHeaderRowProps) => {
  const {
    columns,
    onColumnClick,
    showCheckboxColumn
    // onCheckAll,
    // checkedAll = false
  } = props;

  const checkboxHeader = (
    <div
      className={`
      grid-cell default-grey-label checkbox-column-header
    `}
    >
      {/* TODO: Should be implemented in https://pgga-es.atlassian.net/browse/APM-20432 */}
      {/* <Checkbox
        onChange={(e) => {
          if (onCheckAll) onCheckAll(e);
        }}
        checked={checkedAll}
      /> */}
    </div>
  );

  const cells = columns.map((c) => (
    <HeaderCell
      key={c.config.id}
      column={c}
      columns={columns}
      onColumnClick={onColumnClick}
    />
  ));

  return (
    <div className="grid-row">
      {showCheckboxColumn && checkboxHeader}
      {cells}
    </div>
  );
};

export default HeaderRow;
