// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Map } from "immutable";
import dayjs from "dayjs";

import Data from "core/data/models/Data";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueEditor from "features/detailpage/features/issues/models/IIssueEditor";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueMeta from "features/detailpage/features/issues/models/IssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { IState } from "features/detailpage/features/issues/reducers/IssuesReducer";
import IComment from "features/detailpage/models/IComment";
import IssueMetaRecord from "../models/IssueMetaRecord";

export default class IssuesService {
  static addActiveIssueComment(
    state: IState,
    issueId: string,
    request: JQuery.jqXHR,
    comment: IComment
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);

    if (isActive) {
      const issue = IssuesService.getActiveIssue(state, issueId);
      issue.Comments.push(comment);
      issue.Comments = [...issue.Comments];
      return IssuesService.setActiveIssue(state, issue);
    }

    return state;
  }

  static changeActiveIssueStatus(
    state: IState,
    issueId: string,
    status: IssueStatuses
  ): IState {
    if (status !== "Closed") {
      return IssuesService.setActiveIssueStatus(state, issueId, status);
    } else {
      state = IssuesService.setActiveIssueStatus(state, issueId, status);
      state = IssuesService.moveActiveIssueToClosed(state, issueId);
      state = IssuesService.moveActiveMetaToClosed(state, issueId);
      return state;
    }
  }

  static createActiveIssueMetas(state: IState): IState {
    const metas =
      state.issues.activeIssues &&
      state.issues.activeIssues.data &&
      state.issues.activeIssues.data.map(IssuesService.mapToMetaArray);

    return IssuesService.setActiveIssueMetas(state, Map(metas));
  }

  static createClosedIssueMetas(state: IState): IState {
    const metas =
      state.issues.closedIssues &&
      state.issues.closedIssues.data &&
      state.issues.closedIssues.data.map(IssuesService.mapToMetaArray);

    return IssuesService.setClosedIssueMetas(state, Map(metas));
  }

  static createNewIssue(
    state: IState,
    assetId: string,
    issueId: string,
    username: string
  ): IState {
    const editor: IIssueEditor = {
      EditorUserId: null,
      NameAndSurname: username
    };

    const issue: IIssue = {
      AssetId: assetId,
      Comments: null,
      CloseDate: null,
      ClosedBy: null,
      ConditionText: null,
      Cost: null,
      CreateDate: dayjs().toISOString(),
      CreatedBy: editor,
      HeaderText: null,
      Id: issueId,
      Impact: "Medium",
      IsManualIssue: true,
      LastOccurrenceDate: dayjs().toISOString(),
      LastUpdateBy: editor,
      MaintenancePriority: null,
      MaintenancePriorityScoreOrder: null,
      RecommendationText: null,
      Status: IssueStatuses.InProgress,
      TimeframeText: null,
      TotalNumberOfIssues: null,
      UpdateDate: dayjs().toISOString(),
      Urgency: null,
      WorkOrders: null,
      WorkRequest: null,
      AssetModelId: null,
      AssetModelImplementationId: null
    };

    const meta: IIssueMeta = {
      id: issueId,
      mode: IssueModes.Create,
      createRequest: null,
      commentCreateRequest: null,
      editRequest: null,
      editStatusRequest: null
    };

    return {
      ...state,
      issues: {
        ...state.issues,
        newIssue: issue,
        newMeta: new IssueMetaRecord(meta) as IssueMeta
      }
    };
  }

  static removeNewIssue(state: IState): IState {
    state.issues.newIssue = null;
    state.issues.newMeta = null;

    return {
      ...state,
      issues: {
        ...state.issues
      }
    };
  }

  static saveIssue(
    state: IState,
    request: JQuery.jqXHR,
    issue: IIssue
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issue.Id);

    if (isActive) {
      state = IssuesService.setActiveIssue(state, issue);
      state = IssuesService.setActiveIssueMetaProperty(
        state,
        issue.Id,
        "editRequest",
        request
      );
      state = IssuesService.setActiveIssueMetaProperty(
        state,
        issue.Id,
        "mode",
        IssueModes.View
      );
    } else {
      const meta: IIssueMeta = {
        id: issue.Id,
        mode: IssueModes.View,
        createRequest: new Data(request),
        commentCreateRequest: null,
        editRequest: null,
        editStatusRequest: null
      };

      state = IssuesService.setActiveIssue(state, issue);
      state = IssuesService.setActiveIssueMeta(state, issue.Id, meta);
      state = IssuesService.removeNewIssue(state);
    }

    if (issue.Status === "Closed") {
      state = IssuesService.moveActiveIssueToClosed(state, issue.Id);
      state = IssuesService.moveActiveMetaToClosed(state, issue.Id);
    }

    return state;
  }

  static setActiveIssueCommentCreateRequest(
    state: IState,
    issueId: string,
    request: JQuery.jqXHR,
    data: IComment
  ): IState {
    return IssuesService.setActiveIssueMetaProperty(
      state,
      issueId,
      "commentCreateRequest",
      new Data(request, data)
    );
  }

  static setActiveIssueEditRequest(
    state: IState,
    issueId: string,
    request: JQuery.jqXHR,
    data: IIssue
  ): IState {
    return IssuesService.setActiveIssueMetaProperty(
      state,
      issueId,
      "editRequest",
      new Data(request, data)
    );
  }

  static setActiveIssueEditStatusRequest(
    state: IState,
    issueId: string,
    request: JQuery.jqXHR
  ): IState {
    return IssuesService.setActiveIssueMetaProperty(
      state,
      issueId,
      "editStatusRequest",
      new Data(request)
    );
  }

  static setActiveIssues(
    state: IState,
    request: JQuery.jqXHR,
    data: IIssue[]
  ): IState {
    const issues = { ...state.issues };
    issues.activeIssues = new Data(request, data);

    return {
      ...state,
      issues
    };
  }

  static setClosedIssues(
    state: IState,
    request: JQuery.jqXHR,
    data: IIssue[]
  ): IState {
    const issues = { ...state.issues };
    issues.closedIssues = new Data(request, data);

    return {
      ...state,
      issues
    };
  }

  static setNewIssueCreateRequest(
    state: IState,
    request: JQuery.jqXHR,
    data: IIssue
  ): IState {
    const newMeta = state.issues.newMeta.set(
      "createRequest",
      new Data(request, data)
    ) as IssueMeta;

    return {
      ...state,
      issues: {
        ...state.issues,
        newMeta
      }
    };
  }

  static setStatuses(
    state: IState,
    request: JQuery.jqXHR,
    data: IssueStatuses[]
  ): IState {
    return {
      ...state,
      issues: {
        ...state.issues,
        statuses: new Data(request, data)
      }
    };
  }

  static setUrgencies(
    state: IState,
    request: JQuery.jqXHR,
    data: string[]
  ): IState {
    return {
      ...state,
      issues: {
        ...state.issues,
        urgencies: new Data(request, data)
      }
    };
  }

  static switchIssueMode(
    state: IState,
    issueId: string,
    mode: IssueModes
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);
    const isClosed = IssuesService.isClosedIssue(state, issueId);

    let metas: Map<string, IssueMeta>;
    if (isActive) {
      metas = state.issues.activeMetas;
    } else if (isClosed) {
      metas = state.issues.closedMetas;
    }

    if (metas) {
      metas.toArray().forEach((m) => {
        metas = metas.updateIn([m[1].id, "mode"], () =>
          m[1].id === issueId ? mode : IssueModes.View
        );
      });
    }

    if (isActive) {
      return IssuesService.setActiveIssueMetas(state, metas);
    } else if (isClosed) {
      return IssuesService.setClosedIssueMetas(state, metas);
    }

    return state;
  }

  static setActiveIssue(state: IState, issue: IIssue): IState {
    const issueIndex = IssuesService.getActiveIssueIndex(state, issue.Id);
    const activeIssuesData = state.issues.activeIssues.data
      ? [...state.issues.activeIssues.data]
      : [];

    if (issueIndex >= 0) {
      activeIssuesData[issueIndex] = issue;
    } else {
      activeIssuesData.unshift(issue);
    }

    const activeIssues = state.issues.activeIssues.setData([
      ...activeIssuesData
    ]);

    return {
      ...state,
      issues: {
        ...state.issues,
        activeIssues
      }
    };
  }

  private static getActiveIssueIndex(state: IState, issueId: string): number {
    return state.issues.activeIssues.data.findIndex((i) => i.Id === issueId);
  }

  private static getActiveIssue(state: IState, issueId: string): IIssue {
    const issueIndex = IssuesService.getActiveIssueIndex(state, issueId);
    return { ...state.issues.activeIssues.data[issueIndex] };
  }

  private static isActiveIssue(state: IState, issueId: string): boolean {
    return (
      state.issues.activeIssues &&
      state.issues.activeIssues.data &&
      state.issues.activeIssues.data.filter((i) => i.Id === issueId).length > 0
    );
  }

  private static isClosedIssue(state: IState, issueId: string): boolean {
    return (
      state.issues.closedIssues &&
      state.issues.closedIssues.data &&
      state.issues.closedIssues.data.filter((i) => i.Id === issueId).length > 0
    );
  }

  private static mapToMetaArray(issue: IIssue): [string, IssueMeta] {
    const meta: IIssueMeta = {
      id: issue.Id,
      mode: IssueModes.View,
      createRequest: null,
      commentCreateRequest: null,
      editRequest: null,
      editStatusRequest: null
    };
    return [issue.Id, new IssueMetaRecord(meta)];
  }

  private static moveActiveIssueToClosed(
    state: IState,
    issueId: string
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);
    if (!isActive) return state;
    return IssuesService.removeActiveIssue(state, issueId);
  }

  private static moveActiveMetaToClosed(
    state: IState,
    issueId: string
  ): IState {
    const closedMeta = state.issues.activeMetas.get(issueId);
    const activeMetas = state.issues.activeMetas.remove(issueId);
    const closedMetas = state.issues.closedMetas.set(issueId, closedMeta);

    return {
      ...state,
      issues: {
        ...state.issues,
        activeMetas,
        closedMetas
      }
    };
  }

  private static removeActiveIssue(state: IState, issueId: string): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);
    if (!isActive) return state;

    const issueIndex = IssuesService.getActiveIssueIndex(state, issueId);
    const activeIssuesData = [...state.issues.activeIssues.data];
    activeIssuesData.splice(issueIndex, 1);
    const activeIssues = state.issues.activeIssues.setData([
      ...activeIssuesData
    ]);

    return {
      ...state,
      issues: {
        ...state.issues,
        activeIssues
      }
    };
  }

  private static setActiveIssueMeta(
    state: IState,
    issueId: string,
    meta: IIssueMeta
  ): IState {
    state.issues.activeMetas = state.issues.activeMetas.set(
      issueId,
      new IssueMetaRecord(meta) as IssueMeta
    );

    return {
      ...state,
      issues: {
        ...state.issues
      }
    };
  }

  private static setActiveIssueMetaProperty<T>(
    state: IState,
    issueId: string,
    propertyName: string,
    propertyValue: T
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);
    if (!isActive) return state;

    state.issues.activeMetas = state.issues.activeMetas.setIn(
      [issueId, propertyName],
      propertyValue
    );

    return {
      ...state,
      issues: {
        ...state.issues
      }
    };
  }

  private static setActiveIssueStatus(
    state: IState,
    issueId: string,
    status: IssueStatuses
  ): IState {
    const isActive = IssuesService.isActiveIssue(state, issueId);
    if (!isActive) return state;

    const issue = IssuesService.getActiveIssue(state, issueId);
    issue.Status = status;
    return IssuesService.setActiveIssue(state, issue);
  }

  private static setActiveIssueMetas(
    state: IState,
    metas: Map<string, IssueMeta>
  ) {
    const issues = { ...state.issues };
    issues.activeMetas = metas;

    return {
      ...state,
      issues
    };
  }

  private static setClosedIssueMetas(
    state: IState,
    metas: Map<string, IssueMeta>
  ) {
    const issues = { ...state.issues };
    issues.closedMetas = metas;

    return {
      ...state,
      issues
    };
  }
}
