// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import RogersRatio3DChartWrapper from "features/detailpage/features/rogersratio/components/RogersRatio3D/RogersRatio3DChartWrapper";
import { isEqual } from "lodash";
import * as React from "react";
import { useIntl } from "react-intl";
import { RogersRatioData } from "../models/RogersRatioData";
import RogersRatio2DChart from "./RogersRatio2D/RogersRatio2DChart";

type RogersRatioProps = {
  rogersRatioData: Data<RogersRatioData>;
};

const RogersRatio: React.FC<RogersRatioProps> = ({ rogersRatioData }) => {
  const { data, status, responseJSON } = rogersRatioData;
  const { Loading, Succeeded, NotFound, Failed } = Statuses;
  const intl = useIntl();

  return (
    <div className="rogers-ratio__wrapper">
      {isEqual(status, Loading) ? (
        <Processing className="spinner small dark" />
      ) : isEqual(status, Succeeded) ? (
        data.UseRogersRatio2D ? (
          <RogersRatio2DChart data={data.RogersRatio2D} />
        ) : (
          <RogersRatio3DChartWrapper rogersRatioData={data} />
        )
      ) : isEqual(status, NotFound) ? (
        responseJSON ? (
          <WidgetErrorMessage
            messageId={`detail_page.widgets.analytics.transformers.error.${responseJSON}`}
            messageDefault={intl.formatMessage({
              id: "detail_page.widgets.analytics.transformers.error.visualization_is_not_available",
              defaultMessage: "The visualization is not available"
            })}
          />
        ) : (
          <WidgetNoDataMessage />
        )
      ) : isEqual(status, Failed) ? (
        <WidgetErrorMessage
          messageId="global.server_error"
          messageDefault="Sorry, we've got problems with server connection."
        />
      ) : (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      )}
    </div>
  );
};

export default RogersRatio;
