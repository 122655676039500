// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {closeIssue as closeIssueAction} from "features/issues/actions/IssuesActions";
import {useCallback} from "react";
import {useAppDispatch} from "store";

const useCloseIssueAction = () => {
  const dispatch = useAppDispatch();
    return useCallback(
      (issueId: string, reason: string) => closeIssueAction(dispatch, issueId, reason),
      [dispatch]
  );
};

export default useCloseIssueAction;
