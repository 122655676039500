// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col } from "antd";
import IAssetDetails from "models/IAssetDetails";
import { memo, useContext } from "react";
import AssetRibbonContext from "../contexts/AssetRibbonContext";
import Back from "./Back";
import Details from "./Details";
import Divider from "./Divider";
import LastUpdate from "./LastUpdate";
import Messages from "./Messages";
import Parameters from "./Parameters";
import ReplacementScore from "./ReplacementScore";
import RibbonToggle from "./RibbonToggle";
import RiskInfo from "./RiskInfo";

interface IAssetRibbonSuccededContextProps {
  sidePanelExpanded: boolean;
  assetId: string;
}

interface IAssetRibbonSuccededOwnProps {
  assetDetails: IAssetDetails;
}

type AssetRibbonSuccededProps = IAssetRibbonSuccededOwnProps &
  IAssetRibbonSuccededContextProps;

const AssetRibbonSucceded = memo(
  ({ assetId, assetDetails, sidePanelExpanded }: AssetRibbonSuccededProps) => {
    return (
      <>
        <Col span={8} data-qa="header-main-part">
          <Back />
          <Divider />
          <Details
            parentId={assetDetails?.NameplateWithModelInfo?.ParentId}
            name={assetDetails?.NameplateWithModelInfo?.Name}
            modelId={assetDetails?.NameplateWithModelInfo?.ModelId}
            assetType={assetDetails?.NameplateWithModelInfo?.AssetType}
            status={assetDetails?.NameplateWithModelInfo?.Status}
            lifecycleStatus={
              assetDetails?.NameplateWithModelInfo.LifecycleStatus
            }
          />
        </Col>
        <Col span={3} data-qa="header-last-update-part">
          <Divider />
          <LastUpdate
            degradationScoreDate={assetDetails?.DegradationScoreDate}
            degradationScoreExecutionDate={
              assetDetails?.DegradationScoreExecutionDate
            }
            latestParameterDate={assetDetails?.LatestParameterDate}
            statusUpdateInfo={assetDetails?.StatusUpdateInfo}
          />
        </Col>
        <Col span={sidePanelExpanded ? 2 : 5}>
          <Divider />
          <RibbonToggle dataQa="header-risk-part" to="Risk" assetId={assetId}>
            <RiskInfo
              condition={assetDetails?.DegradationScore}
              risk={assetDetails?.Risk}
              isInFamily={assetDetails?.IsInFamily}
              importance={assetDetails?.NameplateWithModelInfo?.Importance}
              lifecycleStatus={
                assetDetails.NameplateWithModelInfo.LifecycleStatus
              }
            />
          </RibbonToggle>
        </Col>
        <Col span={sidePanelExpanded ? 3 : 2}>
          <Divider />
          <RibbonToggle
            dataQa="header-parameters-part"
            to="Parameters"
            assetId={assetId}
          >
            <Parameters parametersCount={assetDetails?.ParametersCount} />
          </RibbonToggle>
        </Col>
        <Col span={sidePanelExpanded ? 3 : 2}>
          <Divider />
          <RibbonToggle
            dataQa="header-messages-part"
            to="Messages"
            assetId={assetId}
          >
            <Messages
              hasAlertMessages={assetDetails?.HasAlertMessages}
              hasWarningMessages={assetDetails?.HasWarningMessages}
              messagesCount={assetDetails?.MessagesCount}
            />
          </RibbonToggle>
        </Col>
        <Col span={sidePanelExpanded ? 5 : 4}>
          <Divider />
          <RibbonToggle
            dataQa="header-replacement-part"
            to="RPS"
            assetId={assetId}
          >
            <ReplacementScore
              assetType={assetDetails?.NameplateWithModelInfo?.AssetType}
              isWarning={assetDetails?.IsReplacementScoreWarning}
              rank={assetDetails?.ReplacementScoreRank}
              total={assetDetails?.ReplacementScoreTotal}
              replacementScore={assetDetails?.ReplacementScoreValue}
              isInFamily={assetDetails?.IsInFamily}
              lifecycleStatus={
                assetDetails.NameplateWithModelInfo.LifecycleStatus
              }
            />
          </RibbonToggle>
        </Col>
      </>
    );
  }
);

export default (props: IAssetRibbonSuccededOwnProps) => {
  const { sidePanelExpanded, assetId } = useContext(AssetRibbonContext);

  return (
    <AssetRibbonSucceded
      {...props}
      assetId={assetId}
      sidePanelExpanded={sidePanelExpanded}
    />
  );
};
