// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ParameterCharts, {
  ITranslations
} from "@apm/widgets/build/widgets/ParameterCharts";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { loadTransformerParametersAction } from "../../actions/BushingOfflineActions";
import IBushingOfflineDataResult from "../../models/IBushingOfflineDataResult";
import getTransformerParametersSelector from "../../selectors/getTransformerParametersSelector";
import getLegend from "../../utils/getLegend";
import useBushingOfflineCharts from "./hooks/useBushingOfflineCharts";

export interface IBushingOfflineTabDataProps {
  transformerParameters: Data<IBushingOfflineDataResult[]>;
}

export interface IBushingOfflineTabActionsProps {
  loadTransformerParameters: (assetId: string) => void;
}

export interface IBushingOfflineTabDataOwnProps {
  assetId: string;
}

type BushingOfflineComponentProps = IBushingOfflineTabDataOwnProps &
  IBushingOfflineTabActionsProps &
  IBushingOfflineTabDataProps;

const BushingOfflineComponent = ({
  assetId,
  transformerParameters,
  loadTransformerParameters
}: BushingOfflineComponentProps) => {
  const intl = useIntl();

  const dataSourceConfig = useMemo(
    () => ({ showLabel: false, defaultDataSource: "default" }),
    []
  );

  const { bushings } = useBushingOfflineCharts(
    assetId,
    transformerParameters.data,
    loadTransformerParameters
  );

  return (
    <>
      {transformerParameters?.status === Statuses.Loading && <Processing />}
      {transformerParameters?.status === Statuses.Succeeded && (
        <ParameterCharts
          chartGroups={bushings}
          showAlignChartScalingSwitch={!!bushings}
          translations={
            {
              alignChartScaling: intl.formatMessage({
                defaultMessage: "Align chart scaling",
                id: "chart.align_scaling.label"
              }),
              empty: intl.formatMessage({
                defaultMessage: "No data available",
                id: "global.chart.no_data_available"
              })
            } as ITranslations
          }
          dataSourceConfig={dataSourceConfig}
          formatTooltipTitle={(date) => formatDateTime(intl, date)}
          formatXTick={(date) => intl.formatDate(date)}
          getLegend={() => getLegend(intl)}
        />
      )}
      {transformerParameters?.status === Statuses.NotFound && (
        <div>
          <WidgetNoDataMessage />
        </div>
      )}
      {transformerParameters?.status === Statuses.Failed && (
        <div>
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={transformerParameters?.message}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IBushingOfflineTabDataOwnProps
): IBushingOfflineTabDataProps => ({
  transformerParameters: getTransformerParametersSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IBushingOfflineTabActionsProps => ({
  loadTransformerParameters: (assetId: string) =>
    dispatch(loadTransformerParametersAction(assetId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BushingOfflineComponent);
