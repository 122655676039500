// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IssueCompact, {
  IIssueCompactActions
} from "features/detailpage/features/issues/components/IssueCompact";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch } from "store";
import { editIssueStatus, updateActiveIssue } from "../actions/IssuesActions";
import { IssueStatuses } from "../models/IssueStatuses";

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueCompactActions {
  return {
    editIssueStatus: (
      intl: IntlShape,
      issueId: string,
      status: IssueStatuses,
      comment: string,
    ) => editIssueStatus(dispatch, intl, issueId, status, comment),
    updateActiveIssue: (issueId) => updateActiveIssue(dispatch, issueId)
  };
}

export default connect(undefined, mapDispatchToProps)(IssueCompact);
