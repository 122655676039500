// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IColumn, IRow } from "common/datagrid/DataGrid";
import HeaderRow from "common/datagrid/HeaderRow";
import { IProperties } from "common/datagrid/services/PropertyService";
import * as React from "react";

export interface IGridHeaderProps {
  columns: IColumn[];
  fixed?: boolean;
  gridId: string;
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
  visible?: boolean;
  width?: number;
  showCheckboxColumn?: boolean;
  onCheckAll?: (e: CheckboxChangeEvent) => void;
  checkedAll?: boolean;
  checkedRows: IRow[];
}

export interface IGridHeaderState {}

export default class GridHeader extends React.Component<
  IGridHeaderProps,
  IGridHeaderState
> {
  static defaultProps = {
    fixed: false,
    visible: true
  };

  static showFixedHeader(properties: IProperties): boolean {
    if (!properties) return false;

    const isHeaderInBoundingBox =
      properties.headerTop - properties.parentTop < 0;
    const isFooterInBoundingBoxOrBelow =
      properties.footerTop - properties.parentTop - properties.headerHeight >
        0 || properties.footerTop === undefined;
    const isVisible = properties.headerHeight > 0 && properties.headerWidth > 0;

    return isHeaderInBoundingBox && isVisible && isFooterInBoundingBoxOrBelow;
  }

  render() {
    const {
      columns,
      fixed,
      onColumnClick,
      visible,
      width,
      showCheckboxColumn,
      checkedRows,
      onCheckAll,
      checkedAll
    } = this.props;

    const style = fixed
      ? {
          display: !visible && "none",
          top: 0,
          width: width
        }
      : undefined;

    return (
      <div
        className={`
                    grid-header
                    ${fixed ? "fixed" : "static"}
                `}
        style={style}
      >
        <HeaderRow
          columns={columns}
          onColumnClick={onColumnClick}
          showCheckboxColumn={showCheckboxColumn}
          onCheckAll={onCheckAll}
          checkedAll={checkedAll}
          checkedRows={checkedRows}
        />
      </div>
    );
  }
}
