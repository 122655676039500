// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IValidationResult } from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import { ISelectOption } from "@apm/widgets/build/widgets/DataGrid/models/IGridColumn";
import isSelectOption from "features/ConfigurationTool/utils/isSelectOption";
import { isBoolean, isEmpty, isNil, isNumber, isString } from "lodash";
import IField from "models/IField";
import { TypedValueEnum } from "models/ITypedValue";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { buildYup } from "schema-to-yup";
import ValueType from "../models/ValueType";

const useParametersDataValidation = (configuredParameterNames: string[]) => {
  const intl = useIntl();

  const defaultMessages = useMemo(() => {
    return {
      //common
      required: intl.formatMessage({
        id: "bulk_inspection.validation.value_is_required",
        defaultMessage: "Value is required"
      }),
      exclusiveMinimum: intl.formatMessage({
        id: "bulk_inspection.validation.value_greater_than",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be greater than ${more}"
      }),
      exclusiveMaximum: intl.formatMessage({
        id: "bulk_inspection.validation.value_less_than",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be less than ${less}"
      }),
      minimum: intl.formatMessage({
        id: "bulk_inspection.validation.value_greater_or_equal_to",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be greater than or equal to ${min}"
      }),
      maximum: intl.formatMessage({
        id: "bulk_inspection.validation.value_less_or_equal_to",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be less than or equal to ${max}"
      }),
      number: intl.formatMessage({
        id: "bulk_inspection.validation.value_number",
        defaultMessage: "Value must be a number"
      }),
      integer: intl.formatMessage({
        id: "bulk_inspection.validation.value_whole_number",
        defaultMessage: "Value must be a whole number"
      }),
      date: intl.formatMessage({
        id: "bulk_inspection.validation.value_date",
        defaultMessage: "Value must be a date"
      }),
      //specific to bulk
      valueTypeRequired: intl.formatMessage({
        id: "bulk_inspection.validation.value_type_is_required",
        defaultMessage: "Value Type is required"
      }),
      dateFromPast: intl.formatMessage({
        id: "bulk_inspection.validation.date_not_from_future",
        defaultMessage: "Date can't be from the future"
      }),
      incorrectType: intl.formatMessage({
        id: "bulk_inspection.validation.incorrect_type",
        defaultMessage: "Incorrect data type"
      }),
      unsupported: intl.formatMessage({
        id: "bulk_inspection.validation.unsupported_error_type",
        defaultMessage: "Unsupported error type"
      })
    };
  }, [intl]);

  const getValuesFromListValues = useCallback(
    (listValues: string[] | boolean[] | number[] | ISelectOption[]) => {
      if (isSelectOption(listValues)) return listValues.map((val) => val.value);
      return listValues.map((item) => {
        return isBoolean(item) ? String(item) : item;
      });
    },
    []
  );

  const defaultSchema = useMemo(() => {
    return {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "http://example.com/person.schema.json",
      type: "object"
    };
  }, []);

  const valueIsRequiredError = useMemo((): IValidationResult => {
    return { valid: false, validationMessage: defaultMessages.required };
  }, [defaultMessages.required]);

  const validateNumber = useCallback(
    (value: ValueType): IValidationResult => {
      const result = isNumber(value);
      return result
        ? { valid: true }
        : { valid: false, validationMessage: defaultMessages.number };
    },
    [defaultMessages.number]
  );

  const validateString = useCallback(
    (value: ValueType): IValidationResult => {
      const result = isString(value);
      return result
        ? { valid: true }
        : { valid: false, validationMessage: defaultMessages.incorrectType };
    },
    [defaultMessages.incorrectType]
  );

  const validateDate = useCallback(
    (
      value: ValueType,
      futureDatesDisabled: boolean = false
    ): IValidationResult => {
      if (isBoolean(value))
        return { valid: false, validationMessage: defaultMessages.date };

      if (!value)
        return { valid: false, validationMessage: defaultMessages.required };

      let result =
        typeof value === "object"
          ? { valid: true }
          : { valid: false, validationMessage: defaultMessages.date };

      if (result.valid && futureDatesDisabled)
        result = dayjs(value).isAfter(dayjs())
          ? { valid: false, validationMessage: defaultMessages.dateFromPast }
          : { valid: true };
      return result;
    },
    [defaultMessages]
  );

  const validateBooleanListOption = useCallback(
    (value: ValueType): IValidationResult => {
      if ([true.toString(), false.toString()].includes(value.toString()))
        return { valid: true };
      else return { valid: false, validationMessage: defaultMessages.required };
    },
    [defaultMessages.required]
  );

  const validateAssetId = useCallback(
    (assetId: string): IValidationResult => {
      const result = !isEmpty(assetId);
      return result ? { valid: true } : valueIsRequiredError;
    },
    [valueIsRequiredError]
  );

  const validateParameterName = useCallback(
    (parameterName: string): IValidationResult => {
      if (isEmpty(parameterName)) return valueIsRequiredError;
      if (!configuredParameterNames.includes(parameterName))
        return { valid: true, warning: true };

      return { valid: true };
    },
    [configuredParameterNames, valueIsRequiredError]
  );

  const validateValueType = useCallback(
    (
      valueType: string,
      fieldConfiguration: IField,
      assetType: string
    ): IValidationResult => {
      if (!isNil(fieldConfiguration) && assetType === "Transformer") {
        return valueType === fieldConfiguration.dataType
          ? { valid: true }
          : {
              valid: false,
              validationMessage: intl.formatMessage(
                {
                  id: "bulk_inspection.validation.value_should_be_equal_to",
                  // eslint-disable-next-line no-template-curly-in-string
                  defaultMessage: "Value should be equal to {value}"
                },
                { value: fieldConfiguration.dataType }
              )
            };
      } else
        return Object.keys(TypedValueEnum).includes(valueType)
          ? { valid: true }
          : valueIsRequiredError;
    },
    [intl, valueIsRequiredError]
  );

  const validateValue = useCallback(
    (
      newValue: ValueType,
      valueType: string,
      fieldConfiguration: IField,
      assetType: string
    ): IValidationResult => {
      if (isNil(newValue))
        return { valid: false, validationMessage: defaultMessages.required };

      let validationResult: IValidationResult = {
        valid: false,
        validationMessage: defaultMessages.unsupported
      };

      if (!isNil(fieldConfiguration) && assetType === "Transformer") {
        if (fieldConfiguration.validationSchema) {
          if (
            fieldConfiguration.validationSchema.type === "integer" &&
            !Number.isInteger(newValue)
          )
            return (validationResult = {
              valid: false,
              validationMessage: defaultMessages.integer
            });

          const schema = {
            ...defaultSchema,
            properties: {
              value: { ...fieldConfiguration.validationSchema }
            }
          };
          const yupSchema = buildYup(schema, {
            errMessages: {
              value: {
                ...defaultMessages
              }
            }
          });
          try {
            yupSchema.validateSync({ value: newValue });
            validationResult = { valid: true };
          } catch (e) {
            return (validationResult = {
              valid: false,
              validationMessage: e.errors
            });
          }
          if (fieldConfiguration.disableFutureDates) {
            validationResult = validateDate(
              newValue,
              fieldConfiguration.disableFutureDates
            );
          }
        } else if (
          fieldConfiguration.inputType === "list" &&
          typeof newValue !== "object"
        ) {
          const values = getValuesFromListValues(fieldConfiguration.listValues);
          if (
            values.includes(isBoolean(newValue) ? String(newValue) : newValue)
          )
            validationResult = { valid: true };
          else
            return (validationResult = {
              valid: false,
              validationMessage: defaultMessages.required
            });
        }
      } else {
        if (isNil(valueType)) {
          return (validationResult = {
            valid: false,
            validationMessage: defaultMessages.valueTypeRequired
          });
        }
        switch (valueType as TypedValueEnum) {
          case TypedValueEnum.Decimal:
            validationResult = validateNumber(newValue);
            break;
          case TypedValueEnum.DateTime:
            validationResult = validateDate(newValue);
            break;
          case TypedValueEnum.Bool:
            validationResult = validateBooleanListOption(newValue);
            break;
          case TypedValueEnum.String:
            validationResult = validateString(newValue);
            break;
        }
      }

      return validationResult;
    },
    [
      defaultMessages,
      defaultSchema,
      getValuesFromListValues,
      validateBooleanListOption,
      validateDate,
      validateNumber,
      validateString
    ]
  );

  return {
    defaultMessages,
    validateAssetId,
    validateParameterName,
    validateDate,
    validateValueType,
    validateValue
  };
};

export default useParametersDataValidation;
