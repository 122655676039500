// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectedFilters } from "common/FilterBar";
import { IDataEndpoint, IRow } from "common/datagrid/DataGrid";
import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import UrlService from "core/data/services/UrlService";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import updateIssuesStatusAction, {
  IUpdateIssuesErrorResponse,
  IUpdateIssuesResponse,
  IUpdateIssuesWarningResponse
} from "features/issues/actions/updateIssuesStatusAction";
import useCloseIssueAction from "features/issues/hooks/useCloseIssueAction";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";
import getMessage, { EResponseMessage } from "../utils/getResponseMessage";
import { isError, isSuccess, isWarning } from "../utils/getResponseStatus";
import useIssuesGridActionConfig, {
  IGridActionCallbacks
} from "./useIssueGridActionsConfig";
import useIssueStatusTranslation from "./useIssueStatusTranslation";
import useIssuesGridColumnsConfig, {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "./useIssuesGridColumnsConfig";

interface IUseGridOptions {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
  user: IUser;
}

interface IIssueStatusChangeModalData {
  visible: boolean;
  assetIssueId?: string;
  title?: string;
  gridActionCallbacks?: IGridActionCallbacks;
}

const useIssueGrid = ({
  filters,
  onMaintenancePriorityClick,
  onNumberOfActionsClick,
  user
}: IUseGridOptions) => {
  const issueStatusTranslation = useIssueStatusTranslation();
  const [rowsTotal, setRowsTotal] = useState<number>();
  const [checkedRows, setCheckedRows] = useState<IRow[]>([]);
  const [shouldUpdateGrid, setShouldUpdateGrid] = useState<boolean>(false);

  const [modalData, setModalData] = useState<IIssueStatusChangeModalData>({
    visible: false
  });

  const closeIssue = useCloseIssueAction();
  const intl = useIntl();

  const showCloseIssueModal = useCallback(
    (
      assetIssueId: string,
      title: string,
      gridActionCallbacks: IGridActionCallbacks
    ) => {
      setModalData({
        visible: true,
        assetIssueId,
        title,
        gridActionCallbacks
      });
    },
    []
  );

  const hideCloseIssueModal = useCallback(() => {
    setModalData({ visible: false });
  }, []);

  const handleChangeIssueConfirm = useCallback(
    async (status: IssueStatuses, reason: string) => {
      const message = getMessage(
        intl,
        checkedRows.length,
        issueStatusTranslation[status]
      );
      try {
        await updateIssuesStatusAction(status, checkedRows, reason).then(
          (response: IUpdateIssuesResponse) => {
            switch (true) {
              case isSuccess(response):
                notifications.success({
                  message: message(EResponseMessage.SUCCESS)
                });
                setCheckedRows([]);
                break;

              case isError(response):
                notifications.error({
                  message: message(
                    EResponseMessage.ERROR,
                    response as IUpdateIssuesErrorResponse
                  )
                });
                break;

              case isWarning(response):
                notifications.warning({
                  message: message(
                    EResponseMessage.WARNING,
                    response as IUpdateIssuesWarningResponse
                  ),
                  duration: null
                });
                const failedIssuesIds = Object.values(
                  (response as IUpdateIssuesWarningResponse).Errors
                ).flat();

                const isFailedIssue = (checkedIssue: IRow) =>
                  !failedIssuesIds.every(
                    (failedIssueId: string) =>
                      failedIssueId !== checkedIssue.data.AssetIssueId
                  );

                const failedIssues = checkedRows.filter((checkedIssue) =>
                  isFailedIssue(checkedIssue)
                );
                setCheckedRows(failedIssues);
                break;

              default:
                notifications.error({
                  message: message(
                    EResponseMessage.ERROR,
                    response as IUpdateIssuesErrorResponse
                  )
                });
            }
            setShouldUpdateGrid(true);
          }
        );
      } catch (e) {
        notifications.error({
          message: message(EResponseMessage.ERROR, e.responseJSON)
        });
      }
    },
    [checkedRows, intl, issueStatusTranslation]
  );

  const handleCloseIssueConfirm = useCallback(
    async (status: IssueStatuses, reason: string) => {
      try {
        modalData?.gridActionCallbacks?.onStarted();
        await closeIssue(modalData?.assetIssueId, reason).then(() => {
          hideCloseIssueModal();
          notifications.success({
            message: intl.formatMessage({
              id: "issues_page.issue.close.success",
              defaultMessage: "Issue was closed"
            })
          });
          setCheckedRows(
            checkedRows.filter(
              (row) => row.data.AssetIssueId !== modalData?.assetIssueId
            )
          );
          modalData?.gridActionCallbacks?.onSucceeded();
        });
      } catch (e) {
        notifications.error({
          message: intl.formatMessage(
            {
              id: "issues_page.issue.close.failed",
              defaultMessage: "Cannot close issue. Response code: {code}"
            },
            { code: e.status }
          )
        });
        modalData?.gridActionCallbacks?.onFailed();
      }
    },
    [checkedRows, closeIssue, hideCloseIssueModal, intl, modalData]
  );

  const columns = useIssuesGridColumnsConfig({
    onMaintenancePriorityClick,
    onNumberOfActionsClick
  });

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user]);

  const defaultActions = useIssuesGridActionConfig(showCloseIssueModal);

  const actions = useMemo(
    () => (isReadOnlyMode ? [] : defaultActions),
    [defaultActions, isReadOnlyMode]
  );

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.watchlist.issuesRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (total, _newRows, loadedRows, isSortOrFilter) => {
        setRowsTotal(total);

        if (isSortOrFilter) {
          setCheckedRows([]);
          return;
        }

        // check if checked rows are still in the grid after data load
        const actualCheckedRows = loadedRows.filter((row) =>
          checkedRows.some(
            (checkedRow) =>
              checkedRow.data.AssetIssueId === row.data.AssetIssueId
          )
        );

        setCheckedRows(actualCheckedRows);
      }
    }),
    [checkedRows, filters]
  );

  const onCheckboxChange = useCallback(
    (e: CheckboxChangeEvent, row: IRow) => {
      setCheckedRows(
        e.target.checked
          ? [...checkedRows, row]
          : checkedRows.filter(
              ({ data }) => data.AssetIssueId !== row.data.AssetIssueId
            )
      );
    },
    [checkedRows]
  );

  const shouldUpdateGridData = useCallback(() => {
    if (!shouldUpdateGrid) {
      return false;
    }

    setShouldUpdateGrid(false);
    return true;
  }, [shouldUpdateGrid]);

  return {
    dataEndpoint,
    rowsTotal,
    actions,
    columns,
    checkedRows,
    checkbox: {
      checkedRows,
      setCheckedRows,
      onCheckboxChange,
      getId: ({ data }: IRow) => data.AssetIssueId,
      isCheckboxHidden: ({ data }: IRow) => data.Status === IssueStatuses.Closed
    },
    hideCloseIssueModal,
    modalVisible: modalData.visible,
    modalTitle: modalData?.title,
    shouldUpdateGridData,
    handleCloseIssueConfirm,
    handleChangeIssueConfirm
  };
};

export default useIssueGrid;
