// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  EErrorTypes,
  IUpdateIssuesErrorResponse,
  IUpdateIssuesResponse,
  IUpdateIssuesSuccessResponse,
  IUpdateIssuesWarningResponse
} from "features/issues/actions/updateIssuesStatusAction";
import { isString } from "lodash";

const isSuccess = (
  response: IUpdateIssuesResponse
): response is IUpdateIssuesSuccessResponse => {
  return response === "";
};

const isWarning = (
  response: IUpdateIssuesResponse
): response is IUpdateIssuesWarningResponse => {
  if (isString(response)) return false;

  return response.SuccessCount > 0 && !!response.Errors;
};

const isError = (
  response: IUpdateIssuesResponse
): response is IUpdateIssuesErrorResponse => {
  if (isString(response)) {
    return response === EErrorTypes.COMMENT_IS_MANDATORY;
  }

  return response.SuccessCount === 0;
};

export { isError, isSuccess, isWarning };
