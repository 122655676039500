// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  EErrorTypes,
  IUpdateIssuesErrorObjectResponse,
  IUpdateIssuesErrorResponse,
  IUpdateIssuesResponse,
  IUpdateIssuesSuccessResponse,
  IUpdateIssuesWarningResponse
} from "features/issues/actions/updateIssuesStatusAction";
import { isObject, isString } from "lodash";
import { IntlShape } from "react-intl";
import styled from "styled-components";

export enum EResponseMessage {
  SUCCESS,
  ERROR,
  WARNING
}

const getErrorsMessages = (
  response: IUpdateIssuesWarningResponse,
  intl: IntlShape
): JSX.Element => (
  <>
    {response.Errors[EErrorTypes.NOT_FOUND_ISSUE_TO_EDIT] && (
      <Text>
        {intl.formatMessage(
          {
            id: "issues_page.issues.update.error.not_found_issue_to_edit",
            defaultMessage:
              "{num} issue statuses cannot change. The issues do not exist or are closed."
          },
          {
            num: response.Errors[EErrorTypes.NOT_FOUND_ISSUE_TO_EDIT]?.length
          }
        )}
      </Text>
    )}
    {response.Errors[
      EErrorTypes.UNABLE_TO_CHANGE_ISSUE_STATUS_WHEN_ASSET_IS_REMOVED
    ] && (
      <Text>
        {intl.formatMessage(
          {
            id: "issues_page.issues.update.error.unable_to_change_issue_status_when_asset_is_removed",
            defaultMessage:
              "{num} issue statuses cannot change. The life cycle status of the asset(s) with these issues is Removed."
          },
          {
            num: response.Errors[
              EErrorTypes.UNABLE_TO_CHANGE_ISSUE_STATUS_WHEN_ASSET_IS_REMOVED
            ]?.length
          }
        )}
      </Text>
    )}
    {response.Errors[
      EErrorTypes.UNABLE_TO_CHANGE_STATUS_WHEN_ISSUE_STATUS_IS_CLOSED
    ] && (
      <Text>
        {intl.formatMessage(
          {
            id: "issues_page.issues.update.error.unable_to_change_issue_status_when_asset_is_closed",
            defaultMessage:
              "{num} issue statuses cannot change because the issues are closed."
          },
          {
            num: response.Errors[
              EErrorTypes.UNABLE_TO_CHANGE_STATUS_WHEN_ISSUE_STATUS_IS_CLOSED
            ]?.length
          }
        )}
      </Text>
    )}
    {response.Errors[EErrorTypes.UNABLE_TO_CHANGE_STATUS_TO_THE_SAME_ONE] && (
      <Text>
        {intl.formatMessage(
          {
            id: "issues_page.issues.update.error.unable_to_change_status_to_the_same_one",
            defaultMessage:
              "{num} issue statuses cannot change. You cannot change the issue status to the same status."
          },
          {
            num: response.Errors[
              EErrorTypes.UNABLE_TO_CHANGE_STATUS_TO_THE_SAME_ONE
            ]?.length
          }
        )}
      </Text>
    )}
    {response.Errors[EErrorTypes.UNABLE_TO_CHANGE_STATUS_WHEN_MODIFIED] && (
      <Text>
        {intl.formatMessage(
          {
            id: "issues_page.issues.update.error.unable_to_change_status_when_modified",
            defaultMessage:
              "{num} issue statuses cannot change. These issue statuses changed before you completed your changes."
          },
          {
            num: response.Errors[
              EErrorTypes.UNABLE_TO_CHANGE_STATUS_WHEN_MODIFIED
            ]?.length
          }
        )}
      </Text>
    )}
  </>
);

const getSuccessText = (intl: IntlShape, num: number, status: string) => (
  <Text>
    {intl.formatMessage(
      {
        id: "issues_page.issues.update.success",
        defaultMessage: "Status of {num} issue(s) was changed to {status}"
      },
      { num, status: <strong>{status}</strong> }
    )}
  </Text>
);

const isSuccessType = (
  type: EResponseMessage,
  _response: IUpdateIssuesResponse
): _response is IUpdateIssuesSuccessResponse =>
  type === EResponseMessage.SUCCESS;

const isWarningType = (
  type: EResponseMessage,
  _response: IUpdateIssuesResponse
): _response is IUpdateIssuesWarningResponse =>
  type === EResponseMessage.WARNING;

const isErrorType = (
  type: EResponseMessage,
  _response: IUpdateIssuesResponse
): _response is IUpdateIssuesErrorResponse => type === EResponseMessage.ERROR;

const isErrorObject = (
  response: IUpdateIssuesErrorResponse
): response is IUpdateIssuesErrorObjectResponse =>
  isObject(response) && !!response.Errors;

const getMessage =
  (intl: IntlShape, num: number, status: string) =>
  (type: EResponseMessage, response?: IUpdateIssuesResponse) => {
    if (isSuccessType(type, response)) {
      return getSuccessText(intl, num, status);
    }

    if (isWarningType(type, response)) {
      return (
        <>
          {getSuccessText(intl, response.SuccessCount, status)}
          {getErrorsMessages(response, intl)}
        </>
      );
    }

    if (isErrorType(type, response)) {
      if (isString(response) && response === EErrorTypes.COMMENT_IS_MANDATORY) {
        return intl.formatMessage(
          {
            id: "issues_page.issues.update.failed.comment",
            defaultMessage:
              "{num} issue statuses cannot change. A comment is mandatory."
          },
          { num }
        );
      }

      if (isErrorObject(response)) {
        return getErrorsMessages(response, intl);
      }

      return intl.formatMessage(
        {
          id: "issues_page.issues.update.failed",
          defaultMessage: "{num} issue statuses cannot change."
        },
        { num }
      );
    }

    return "";
  };

const Text = styled.p`
  margin-bottom: 0;
`;

export default getMessage;
